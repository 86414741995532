import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import bulmaCollapsible from '@creativebulma/bulma-collapsible'
import Chevron from '../img/chevron.svg'

const Accordian = ({ items }) => {
  let collapsibles = React.createRef()
  useEffect(() => {
    bulmaCollapsible.attach('.is-collapsible', {
      container: collapsibles.current
    })
  }, [])

  const rotate = (e) => {
    let currentAngle = e.currentTarget.style['transform']
    let rotation = currentAngle ? '' : `rotate(180deg)`
    e.currentTarget.style['transform'] = rotation
  }

  return (
    <div ref={collapsibles} id='accordion_first'>
      {items.map((item, index) => (
        <article className='message' key={index} style={{
          marginBottom: '.0rem'
        }}>
          <div className='accordian-header message-header'
            style={{
              backgroundColor: '#fff',
              color: '#000'
            }}>
            <span><img src={item.icon.childImageSharp.fluid.src} /></span>
            <p style={{
              width: '100%',
              marginLeft: '30px'
            }}>{item.title}</p>
            <a href={`#collapsible-message-accordion-${index}`}
              data-action='collapse'
              onClick={rotate}>
              <span className='icon'>
                <img src={Chevron} />
                <i className='fas fa-angle-down' aria-hidden='true' />
              </span>
            </a>
          </div>
          <div id={`collapsible-message-accordion-${index}`} className='message-body is-collapsible' data-parent='accordion_first'>
            <div className='message-body-content'
              dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        </article>
      ))}
    </div >
  )
}

Accordian.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      content: PropTypes.string,
      title: PropTypes.string
    })
  )
}

export default Accordian
