import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Vector from '../img/vector.svg'
import IdeationVector from '../img/ideation.svg'

import Layout from '../components/Layout'
import Features from '../components/Features'
import Accordian from '../components/Accordian'

import ContactPage from '../pages/contact'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  services,
  partners
}) => (
  <div>
    {/* Hero */}
    <section className='hero is-full height-with-navbar'
      style={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 23.39%), linear-gradient(180deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(114.53deg, #DDF3EB 32.2%, rgba(111, 188, 246, 0.57) 130.13%)'
      }}>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-vcentered'>
            <div className='column is-6'>
              <h1 className='title hero-text has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen'
                style={{
                  fontFamily: "'Raleway', 'sans-serif'"
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p className='is-size-5-mobile is-size-5-tablet is-size-5-widescreen'
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <div className='column is-6 hero-image' >
              <img src={image.childImageSharp.fluid.src} />
            </div>
            <span className='is-blue' />
          </div>
        </div>
      </div>
    </section>

    {/* Why us? */}
    <section style={{
      background: 'linear-gradient(73.29deg, #DDF2EB 36.46%, rgba(111, 188, 246, 0.61) 126.28%)'
    }}>
      <div className='container'>
        <div className='section'>
          <div className='columns'>
            <div className='column is-12'>
              <div className='content'>
                <div className='tile'>
                  <h1 className='title' style={{
                    width: '100%',
                    textAlign: 'center'
                  }}>{mainpitch.title}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className='columns'>
            <div className='column is-6' >
              <img src={mainpitch.image.childImageSharp.fluid.src} />
            </div>
            <div className='column is-6'>
              <div className='content'>
                <div className='tile'>
                  <p className='subtitle is-5' dangerouslySetInnerHTML={{ __html: mainpitch.description }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Professionalism Defined */}
    <section>
      <img src={Vector} />
      <div style={{
        width: '176px',
        height: '176px',
        left: '10%',
        top: '200px',
        background: 'rgba(221, 242, 235, 0.35)',
        borderRadius: '50%',
        zIndex: '-100',
        position: 'fixed'
      }} />

      <div style={{
        width: '176px',
        height: '176px',
        left: '80%',
        top: '218px',
        background: 'rgba(178, 218, 238, 0.12)',
        borderRadius: '50%',
        zIndex: '-100',
        position: 'fixed'
      }} />

      <div style={{
        width: '176px',
        height: '176px',
        left: '5%',
        top: '644px',
        background: 'rgba(178, 218, 238, 0.12)',
        borderRadius: '50%',
        zIndex: '-100',
        position: 'fixed'
      }} />

      <div style={{
        width: '176px',
        height: '176px',
        left: '60%',
        top: '740px',
        background: 'rgba(221, 242, 235, 0.35)',
        borderRadius: '50%',
        zIndex: '-100',
        position: 'fixed'
      }} />

      <div className='container'>
        <div className='section'>
          <div className='columns'>
            <div className='column is-12'>
              <div className='content'>
                <div className='tile'>
                  <div style={{
                    width: '100%'
                  }}>
                    <h1 className='title'
                      style={{ marginTop: '-100px', textAlign: 'center' }}
                      dangerouslySetInnerHTML={{ __html: intro.heading }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Features gridItems={intro.blurbs} />
        </div>
      </div>
    </section>

    { /* Our services */}
    <section style={{
      background: '#fff',
      padding: '0% 0% 5% 0%'
    }} className='container' ref={services} id='services'>
      <div className='content'>
        <div className='tile'>
          <div style={{
            width: '100%'
          }}>
            <h1 className='title' style={{
              width: '100%',
              textAlign: 'center'
            }}>Our services</h1>
          </div>
        </div>
      </div>
      <div className='columns is-vcentered'>
        <div className='column is-6'>
          <Accordian items={services} />
        </div>
        <div>
          <img src={IdeationVector} />
        </div>
      </div>
    </section>

    <section>
      <div className='container'>
        <div className='section'>
          <div className='columns'>
            <div className='column is-12'>
              <div className='content is-medium has-text-centered'>
                <div className='tile has-text-centered'>
                  <h1 className='title' style={{ width: '100%' }}>{partners.title}</h1>
                </div>
                <div className='tile'>
                  <p className='subtitle is-5'>{partners.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='is-centered is-8' style={{ textAlign: 'center' }}>
            <div className='columns is-multiline is-centered'>
              <div className='column is-4'>
                <img src='../img/marie.png' alt='marie-stopes' />
              </div>
              <div className='column is-4'>
                <img src='../../img/absolute.png' alt='absolute' />
              </div>
              <div className='column is-4'>
                <img src='../../img/vimetco.png' alt='vimetco' />
              </div>
              <div className='column is-4'>
                <img
                  src='../../img/scals.jpg'
                  style={{
                    height: '120px'
                  }}
                  alt='scals' />
              </div>
              <div className='column is-4'>
                <img src='../img/afcom.png' alt='afcom' />
              </div>
              <div className='column is-4'>
                <img
                  src='../img/dho.jpg'
                  style={{
                    height: '120px'
                  }}
                  alt='Domestic Helpers Organisation' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div >
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  }),
  services: PropTypes.array,
  partners: PropTypes.object
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        services={frontmatter.services}
        partners={frontmatter.partners}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
          }
          heading
        }
        services {
          title
          content
          icon {
            childImageSharp {
                fluid(maxWidth: 40, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
          }
        }
        partners {
          title
          description
        }
      }
    }
  }
`
