import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  <div className='columns is-multiline is-centered'>
    {gridItems.map(item => (
      <div key={item.text} className='column is-twelve-mobile is-four-desktop'>
        <section className='section'>
          <div className=''>
            <div
              style={{
                width: '80px',
                display: 'inline-block'
              }}
            >
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
          <h2 style={{ fontSize: '20px' }}>{item.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: item.text }} />
        </section>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string
    })
  )
}

export default FeatureGrid
